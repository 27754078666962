import { useWindowSize } from '@uidotdev/usehooks'
import { format } from 'date-fns'
import { localeMap } from 'libs/dateUtils'
import { useGeneratePassword } from 'libs/hooks/useGeneratePassword'
import { path } from 'libs/path'
import { MetaTraderSettings } from 'pages/accounts/details/MetaTraderSettings'
import { useLanguageChange } from 'pages/giftshop/hooks'
import { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useGetAccount } from 'services/accounts/get/useGetAccount'
import { useGetContestDetails } from 'services/contests/get/useGetContestDetails'
import { useRegister } from 'services/contests/mutations/useRegister'
import { Button, Icon2, Money, Tag, Title } from 'ui/atoms'
import { toCurrency } from 'ui/atoms/money'
import { TradeModal } from 'ui/molecules'
import { Modal } from 'ui/molecules/modal_draft'

import { RatingTable } from '../molecules/ratingTable'
import { ResultMessage } from '../molecules/resultMessage'
import { TeamsBanner } from '../molecules/teamsBanner'
import { Timer } from '../molecules/timer'
import { TradingInfo } from '../molecules/tradingInfo'
import { contestStatuses, participantStatuses } from '../utils'
import * as Styled from './styled'

export const ContestDetails = () => {
  const { contestName } = useParams()

  const [t, i18n] = useTranslation()
  const locale = localeMap[i18n.language]

  const { width } = useWindowSize()

  const { password: generatedPassword } = useGeneratePassword()

  //
  const {
    data,
    isLoading: getContestDetailsLoading,
    isFetching: getContestDetailsFetching,
    refetch: refetchContestDetails,
  } = useGetContestDetails(contestName as string)
  const {
    mutateAsync,
    isSuccess,
    isLoading: registerLoading,
  } = useRegister(contestName as string)

  //
  useLanguageChange(refetchContestDetails)

  //
  const [userAccountId, setUserAccountId] = useState('')

  const { data: userAccount } = useGetAccount(userAccountId, !!userAccountId)

  //
  const [isRegisterModalVisible, setIsRegisterModalVisible] = useState(false)
  const [isMTModalVisible, setIsMTModalVisible] = useState(false)

  // для плавного скролла
  const choiceContainerRef = useRef<HTMLDivElement>(null)
  const accountContainerRef = useRef<HTMLDivElement>(null)

  //
  const hasContestEnded = data && data.contest.status === 'PACS_RESULTS'

  //
  const isWinner =
    data &&
    data.contest.status === 'PACS_RESULTS' &&
    data.participant.place < 10

  //
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' })
  }, [])

  //
  useEffect(() => {
    if (
      data &&
      data.participant.accountInfoStatus !== 'ACCOUNT_INFO_STATUS_INVALID'
    ) {
      setUserAccountId(data.participant.accountID)

      // todo
      // setActiveAccountId(data.participant.accountID)
    }
  }, [data])

  //
  useEffect(() => {
    if (isSuccess) {
      setIsRegisterModalVisible(true)

      refetchContestDetails()
    }
  }, [isSuccess])

  return data ? (
    <Styled.ContentContainer>
      <Styled.Header>
        <Styled.ContestInfo>
          <Styled.InfoTopWrapper>
            <div className="flex gap-[4px]">
              <Tag design={contestStatuses[data.contest.status].tagDesign}>
                {t(contestStatuses[data.contest.status].name)}
              </Tag>
              {data.participant.status !== 'PAPS_UNREGISTERED' && (
                <Tag
                  design={
                    participantStatuses[data.participant.status].tagDesign
                  }
                >
                  {t(participantStatuses[data.participant.status].name)}
                </Tag>
              )}
            </div>
            <div>
              {format(new Date(+data.contest.registrationTs * 1000), 'LLLL d', {
                locale,
              })}{' '}
              —{' '}
              {format(new Date(+data.contest.resultsTs * 1000), 'LLLL d', {
                locale,
              })}
            </div>
          </Styled.InfoTopWrapper>
          <Styled.ContestTitle>{data.contest.title}</Styled.ContestTitle>
          <Styled.ContestDescription>
            {data.contest.description}
          </Styled.ContestDescription>
          <Styled.ContestTerms>
            <div className="flex">
              <Styled.TermTitle>{t('Prize pool')}</Styled.TermTitle>
              <span className="text-eucalyptus.700">
                <Trans
                  t={t}
                  i18nKey="Up to $7,500"
                  values={{
                    value: toCurrency(data.contest.prizeFund, 'USD', 0),
                  }}
                />
              </span>
            </div>
            <div className="flex">
              <Styled.TermTitle>{t('Winners')}</Styled.TermTitle>
              <span className="text-eucalyptus.700">
                {data.contest.numWinners}
              </span>
            </div>
          </Styled.ContestTerms>
          {!hasContestEnded && (
            <Styled.InfoBottomWrapper>
              <div>
                <div className="mb-[12px] text-body.medium leading-body.medium">
                  {data.contest.status === 'PACS_ACTIVE'
                    ? t('Contest ends:')
                    : data.contest.status === 'PACS_ENDED'
                    ? t('Contest results:')
                    : t('Trading starts:')}
                  <span className="ms-[8px] font-medium">
                    {format(
                      new Date(
                        data.contest.status === 'PACS_ACTIVE'
                          ? +data.contest.endedTs * 1000
                          : data.contest.status === 'PACS_ENDED'
                          ? +data.contest.resultsTs * 1000
                          : +data.contest.activeTs * 1000,
                      ),
                      'LLL d, yyyy',
                      {
                        locale,
                      },
                    )}
                  </span>
                </div>
                <Timer
                  targetTimestamp={
                    data.contest.status === 'PACS_ACTIVE'
                      ? data.contest.endedTs
                      : data.contest.status === 'PACS_ENDED'
                      ? data.contest.resultsTs
                      : data.contest.activeTs
                  }
                />
              </div>
              {data.participant.status === 'PAPS_UNREGISTERED' &&
                data.contest.status !== 'PACS_ENDED' && (
                  <Button
                    name="join-contest"
                    size={width && width < 600 ? 'medium' : 'large'}
                    onClick={() => {
                      if (choiceContainerRef.current) {
                        window.scrollTo({
                          top: choiceContainerRef.current.offsetTop,
                          behavior: 'smooth',
                        })
                      }
                    }}
                  >
                    Join
                  </Button>
                )}
            </Styled.InfoBottomWrapper>
          )}
        </Styled.ContestInfo>
        {width &&
          (width < 600 || width > 860) &&
          (width > 1170 || width < 1051) && (
            <Styled.ContestImage>
              <img
                src={
                  width < 600 ? data.contest.image.mob : data.contest.image.web
                }
                alt={data.contest.name}
              />
            </Styled.ContestImage>
          )}
      </Styled.Header>

      <Styled.ContestBody>
        {data.contest.status === 'PACS_RESULTS' && (
          <TeamsBanner teamsBannerBlock={data.contest.teamsBannerBlock} />
        )}

        {data.contest.status === 'PACS_ACTIVE' &&
          data.participant.status === 'PAPS_DISQUALIFIED' && (
            <ResultMessage status="disqualified" />
          )}
        {data.contest.status === 'PACS_ENDED' && (
          <ResultMessage status="calculating" />
        )}
        {data.contest.status === 'PACS_RESULTS' &&
          data.participant.status === 'PAPS_ACTIVE' && (
            <ResultMessage status="ended" participant={data.participant} />
          )}

        {(data.participant.status !== 'PAPS_UNREGISTERED' ||
          data.contest.status === 'PACS_ENDED') &&
          data.contest.status !== 'PACS_RESULTS' && (
            <Styled.VotesWrapper
              vsIcon={
                width && width < 768
                  ? ''
                  : data.contest.teamsBannerBlock.vsIcon.web
              }
              leftImage={
                width && width < 768
                  ? data.contest.teamsBannerBlock.first.image.mob
                  : data.contest.teamsBannerBlock.first.image.web
              }
              rightImage={
                width && width < 768
                  ? data.contest.teamsBannerBlock.second.image.mob
                  : data.contest.teamsBannerBlock.second.image.web
              }
              background={
                width && width < 600
                  ? data.contest.teamsBannerBlock.backgroundImage.mob
                  : data.contest.teamsBannerBlock.backgroundImage.web
              }
            >
              <div>
                <Styled.VotesTitle>
                  {data.contest.teamsBannerBlock.first.name}
                </Styled.VotesTitle>
                <Title
                  withTranslate={false}
                  level={width && width < 600 ? 2 : 1}
                >
                  {data.contest.teamsBannerBlock.first.votes}%
                </Title>
              </div>
              <div>
                <Styled.VotesTitle>
                  {data.contest.teamsBannerBlock.second.name}
                </Styled.VotesTitle>
                <Title
                  withTranslate={false}
                  level={width && width < 600 ? 2 : 1}
                >
                  {data.contest.teamsBannerBlock.second.votes}%
                </Title>
              </div>
            </Styled.VotesWrapper>
          )}

        {data.participant.status === 'PAPS_ACTIVE' && (
          <Styled.UserInfoGrid
            columns={isWinner ? 1 : 2}
            ref={accountContainerRef}
          >
            {isWinner ? null : (
              <Styled.UserInfoWrapper>
                <div>
                  <div className="mb-[4px] text-body.medium leading-body.medium text-gray.600">
                    {t('Your team')}
                  </div>
                  <Title level={2} withTranslate={false}>
                    {data.participant.teamName}
                  </Title>
                </div>
                <div>
                  <div className="mb-[4px] text-body.medium leading-body.medium text-gray.600">
                    {t('Your place')}
                  </div>
                  <Title
                    level={2}
                    withTranslate={data.contest.status === 'PACS_ENDED'}
                  >
                    {data.contest.status === 'PACS_ENDED'
                      ? 'Calculating results'
                      : data.participant.place}
                  </Title>
                </div>
                <div>
                  <div className="mb-[4px] text-body.medium leading-body.medium text-gray.600">
                    {t('Equity')}
                  </div>
                  <Title level={2} withTranslate={false}>
                    <Money value={+data.participant.equity} />
                  </Title>
                </div>
              </Styled.UserInfoWrapper>
            )}
            <Styled.AccountInfoWrapper>
              {data.participant.accountInfoStatus ===
              'ACCOUNT_INFO_STATUS_PREPARING' ? (
                <>
                  <Styled.AccountStatusTitle
                    level={width && width < 600 ? 3 : 2}
                    status="preparing"
                  >
                    {t('Preparing your account')}
                  </Styled.AccountStatusTitle>
                  <Styled.AccountStatusText>
                    {t(
                      'All information about your account will be available here soon',
                    )}
                  </Styled.AccountStatusText>
                </>
              ) : data.participant.accountInfoStatus ===
                'ACCOUNT_INFO_STATUS_CLOSED' ? (
                <>
                  <Styled.AccountStatusTitle
                    level={width && width < 600 ? 3 : 2}
                    status="closing"
                  >
                    {t('Your account was closed')}
                  </Styled.AccountStatusTitle>
                  <Styled.AccountStatusText>
                    {t(
                      'After 90 days, you can no longer view the account history',
                    )}
                  </Styled.AccountStatusText>
                </>
              ) : userAccount ? (
                <div>
                  <Title level={width && width < 600 ? 4 : 3}>
                    {t('MetaTrader 5 settings')}
                  </Title>
                  <MetaTraderSettings
                    accountID={userAccountId}
                    login={userAccount.accountInfo.login}
                    password={
                      userAccount.accountInfo.password ||
                      generatedPassword ||
                      ''
                    }
                    mtServerInfo={userAccount.accountInfo.mtServerInfo}
                    className="!p-0 !border-none mt-[24px] mb-[32px]"
                  />
                  <Button
                    size={width && width < 600 ? 'small' : 'medium'}
                    name="start-trading"
                    design={
                      data.contest.status === 'PACS_REGISTRATION' ||
                      data.contest.status === 'PACS_ACTIVE'
                        ? 'primary'
                        : 'secondary'
                    }
                    onClick={() => setIsMTModalVisible(true)}
                    prefix={<Icon2 name="arrowTrendingUp" />}
                    className="w-full"
                    disabled={data.contest.status === 'PACS_REGISTRATION'}
                  >
                    {data.contest.status === 'PACS_REGISTRATION' ||
                    data.contest.status === 'PACS_ACTIVE'
                      ? 'Trade'
                      : 'Check trading history'}
                  </Button>

                  <Modal
                    open={isMTModalVisible}
                    onClose={() => setIsMTModalVisible(false)}
                    okLabel="Back"
                    title="Trade"
                    width={400}
                  >
                    <TradeModal details={userAccount.accountInfo} />
                  </Modal>
                </div>
              ) : null}
            </Styled.AccountInfoWrapper>
            {data.participant.accountInfoStatus ===
              'ACCOUNT_INFO_STATUS_WILL_CLOSED' && (
              <>
                {width && width > 890 && !isWinner && <div></div>}
                <div className="text-body.small leading-body.small text-center text-gray.600">
                  {t(
                    'Your account is available for 90 days. After this period, the account will be closed',
                  )}
                </div>
              </>
            )}
          </Styled.UserInfoGrid>
        )}

        {((data.contest.status === 'PACS_ACTIVE' &&
          data.participant.status === 'PAPS_ACTIVE') ||
          data.contest.status === 'PACS_RESULTS') && (
          <RatingTable
            leaderboard={data.leaderboard}
            isFull={data.contest.status === 'PACS_ACTIVE'}
          />
        )}

        {((data.contest.status === 'PACS_ACTIVE' &&
          data.participant.status === 'PAPS_UNREGISTERED') ||
          data.contest.status === 'PACS_REGISTRATION') && (
          <Styled.WinnersGrid className="prevent-rtl">
            <Styled.WinnersColumn
              background={
                width && width < 600
                  ? data.contest.winnersBlock.image.mob
                  : data.contest.winnersBlock.image.web
              }
            >
              <Styled.WinnersTitle
                level={width && width < 600 ? 3 : 2}
                withTranslate={false}
              >
                {data.contest.winnersBlock.title}
              </Styled.WinnersTitle>
              <div className="whitespace-pre">
                {data.contest.winnersBlock.listPrizes.join('\n')}
              </div>
              <div className="mt-auto text-body.medium leading-body.medium text-gray.600">
                {data.contest.winnersBlock.footer}
              </div>
            </Styled.WinnersColumn>
            <Styled.WinnersColumn
              background={
                width && width < 768
                  ? data.contest.teamBlock.image.mob
                  : data.contest.teamBlock.image.web
              }
            >
              <Styled.WinnersTitle
                level={width && width < 600 ? 3 : 2}
                withTranslate={false}
              >
                {data.contest.teamBlock.title}
              </Styled.WinnersTitle>
              <div className="whitespace-pre">
                {data.contest.teamBlock.listPrizes.join('\n')}
              </div>
            </Styled.WinnersColumn>
          </Styled.WinnersGrid>
        )}

        {data.participant.status !== 'PAPS_DISQUALIFIED' &&
          (data.contest.status === 'PACS_REGISTRATION' ||
            data.contest.status === 'PACS_ACTIVE') && (
            <TradingInfo
              platform={data.contest.tradingBlock.platform}
              instruments={data.contest.tradingBlock.instruments}
              leverage={data.contest.tradingBlock.leverage}
              initialBalance={data.contest.tradingBlock.initialBalance}
            />
          )}

        {data.participant.status === 'PAPS_UNREGISTERED' &&
          (data.contest.status === 'PACS_REGISTRATION' ||
            data.contest.status === 'PACS_ACTIVE') && (
            <>
              <div className="text-center">
                <Title level={2}>How it works</Title>
                <Styled.HowItWorksWrapper>
                  <Styled.HowItWorksStep>
                    <Title level={4}>Join the contest</Title>
                    <div>{t('Choose your team and start participating')}</div>
                  </Styled.HowItWorksStep>
                  <Styled.HowItWorksStep>
                    <Title level={4}>Trade</Title>
                    <div>{t('Trade your way up the leaderboard')}</div>
                  </Styled.HowItWorksStep>
                  <Styled.HowItWorksStep>
                    <Title level={4}>Win prizes</Title>
                    <div>{t('Finish on top and win cash prizes')}</div>
                  </Styled.HowItWorksStep>
                </Styled.HowItWorksWrapper>
              </div>

              <TeamsBanner
                ref={choiceContainerRef}
                onChoice={(name) => mutateAsync(name)}
                choiceDisabled={registerLoading || getContestDetailsFetching}
                teamsBannerBlock={data.contest.teamsBannerBlock}
                showVotes={data.contest.status === 'PACS_ACTIVE'}
              />
            </>
          )}

        {data.contest.status === 'PACS_ACTIVE' &&
          data.participant.status === 'PAPS_UNREGISTERED' && (
            <RatingTable leaderboard={data.leaderboard} isFull={false} />
          )}

        {((data.contest.status === 'PACS_ACTIVE' &&
          data.participant.status !== 'PAPS_DISQUALIFIED') ||
          data.contest.status === 'PACS_REGISTRATION') && (
          <Styled.TermsWrapper>
            <Title level={2}>Terms</Title>
            {t(
              'Participate in the contest and use a demo account to trade. Your account equity will determine your place in the leaderboard. Top traders will receive monetary prizes.',
            )}
            <Button
              size="small"
              design="tertiary-brand"
              href={path.contests.terms()}
            >
              Terms and conditions
            </Button>
          </Styled.TermsWrapper>
        )}
      </Styled.ContestBody>

      <Modal
        title="You are in"
        open={isRegisterModalVisible}
        onClose={() => {
          setIsRegisterModalVisible(false)
          if (accountContainerRef.current) {
            window.scrollTo({
              top: accountContainerRef.current.offsetTop,
              behavior: 'smooth',
            })
          }
        }}
        okLabel="Let's go"
        icon="success"
      >
        <div className="text-body.regular leading-body.regular text-gray.600 text-center">
          {t(
            'You have joined the contest. Check your contest demo account details and fight for the prize!',
          )}
        </div>
      </Modal>
    </Styled.ContentContainer>
  ) : (
    'loading...'
  )
}
