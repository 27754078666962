import { useTranslation } from 'react-i18next'
import type { Contest } from 'services/contests/types'
import { Money } from 'ui/atoms'

import disqualifiedIcon from '../../images/disqualified-icon.webp'
import { icons } from '../../images/icons'
import overIcon from '../../images/over-icon.webp'
import winnerIcon from '../../images/winner-icon.webp'
import * as Styled from './styled'

export const ResultMessage = ({
  status,
  participant,
}: {
  status: 'disqualified' | 'ended' | 'calculating'
  participant?: Contest['participant']
}) => {
  const [t] = useTranslation()

  return (
    <Styled.Container>
      <Styled.Title
        background={
          status === 'disqualified'
            ? disqualifiedIcon
            : participant && participant.place <= 10
            ? winnerIcon
            : overIcon
        }
      >
        {status === 'disqualified'
          ? 'You are disqualified'
          : status === 'ended' && participant && participant.place <= 10
          ? 'Congratulations'
          : 'The contest is over'}
      </Styled.Title>
      <Styled.Text>
        {status === 'disqualified' && (
          <>
            It seems that you violated the rules of the competition, so we had
            to remove you from the race. If you have any questions or you think
            the disqualification is unfair, please contact us at{' '}
            <a
              href="mailto:help@hw.site"
              className="font-medium text-sunray.700"
            >
              help@hw.site
            </a>
          </>
        )}
        {status === 'calculating' && (
          <>
            {t(
              'We are checking the results now. The final leaderboard will appear here soon.',
            )}
          </>
        )}
        {status === 'ended' && participant && participant.place > 10 && (
          <>{t("Unfortunately, you didn't make it to the top.")}</>
        )}
        {status === 'ended' && participant && participant.place <= 10 && (
          <>{t('You can check the final leaderboard below.')}</>
        )}
      </Styled.Text>

      {participant && participant.place <= 10 && (
        <Styled.PrizeWrapper>
          <Styled.PrizeTopRow>
            <div>
              <Styled.PrizeTitle>{t('Your team')}</Styled.PrizeTitle>
              <Styled.PrizeValue level={3} withTranslate={false}>
                {participant.teamName}
              </Styled.PrizeValue>
            </div>
            <div className='relative'>
              <Styled.PrizeTitle>{t('Your place')}</Styled.PrizeTitle>
              <Styled.PlaceMedal
                src={icons[`medal_${participant.place}`]}
                alt={`${participant.place}`}
              />
            </div>
            <div>
              <Styled.PrizeTitle>{t('Equity')}</Styled.PrizeTitle>
              <Styled.PrizeValue level={3} withTranslate={false}>
                <Money value={+participant.equity} />
              </Styled.PrizeValue>
            </div>
          </Styled.PrizeTopRow>
          <Styled.PrizeBottomRow>
            <div>
              <Styled.PrizeTitle>{t('Prize')}</Styled.PrizeTitle>
              <Styled.PrizeValue level={3} withTranslate={false} highlighted>
                ${participant.prizesReceived.prizeAmount}
              </Styled.PrizeValue>
            </div>
            <div>
              <Styled.PrizeTitle>{t('Superbonus')}</Styled.PrizeTitle>
              <Styled.PrizeValue level={3} withTranslate={false} highlighted>
                ${participant.prizesReceived.prizeBonusAmount}
              </Styled.PrizeValue>
            </div>
          </Styled.PrizeBottomRow>
        </Styled.PrizeWrapper>
      )}
    </Styled.Container>
  )
}
