import { createEvent, createStore, restore, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { toPath } from 'features/router'
import { ClientError, mapResponseError, path } from 'libs'
import { ChangePasswordProps, service } from 'services'

import { registerByEmail } from '../email'

// set passwords

export const setUserPassword = createEvent<string>()
export const $userPassword = restore(setUserPassword, '')

export const $oldPassword = createStore<string>('')

persist({
  store: $oldPassword,
  key: 'generatedPassword',
})

sample({
  clock: registerByEmail.doneData,
  fn: (doneData) => doneData.body.identificationResult.generatedPassword,
  target: [$oldPassword],
})

export const submitForm = createEvent()

// if password changed by user

sample({
  source: [$userPassword, $oldPassword],
  clock: submitForm,
  fn: ([userPassword]) => {
    const action = 'REGISTRATION' as const
    return {
      body: {
        action,
        newPassword: userPassword.trim(),
      },
    }
  },
  target: service.auth.postChangePassword,
})

sample({
  source: service.auth.postChangePassword,
  clock: service.auth.postChangePassword.doneData,
  filter: ({ body }) => body?.action === 'REGISTRATION',
  fn: () => setUserPassword(''),
  target: toPath.prepend(path.register.email.confirm),
})

// handle errors

export const $error = createStore<ClientError<ChangePasswordProps> | null>(null)

$error
  .on(service.auth.postChangePassword.failData, (_, failData) =>
    mapResponseError<ChangePasswordProps>(failData.body),
  )
  .reset(submitForm)
