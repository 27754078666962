import { createEvent, createStore, sample } from 'effector'
import { toPath } from 'features/router'
import { $userProfile, UserProfileType as UserProfile } from 'features/user'
import { ClientError, fx, mapResponseError, path } from 'libs'

import { RegisterByEmail } from '../email'
import { EmailConfirmationRequest } from './types'

export const submitEmailVerifyRepeatForm =
  createEvent<Pick<RegisterByEmail, 'email'>>()

export const emailConfirmationRequest = fx<
  Pick<UserProfile, 'email'>,
  EmailConfirmationRequest
>({
  method: 'POST',
  url: '/user/v1/email-confirmation-request',
})

export const resendEmailConfirmationRequest = fx<
  Pick<UserProfile, 'email'>,
  EmailConfirmationRequest
>({
  method: 'PUT',
  url: '/user/v1/resend-email-confirmation-request',
})

sample({
  source: $userProfile,
  clock: submitEmailVerifyRepeatForm,
  fn: (_, { email }) => ({
    body: {
      email: email.trim(),
    },
  }),
  filter: (userProfile, { email }) => userProfile.email !== email,
  target: emailConfirmationRequest,
})

// если почта не изменена, то фактически запрашиваем
// переотправку кода на тот же email

sample({
  source: $userProfile,
  clock: submitEmailVerifyRepeatForm,
  fn: (_, { email }) => {
    toPath(path.register.email.confirm())

    return {
      body: {
        email: email.trim(),
      },
    }
  },
  filter: (userProfile, { email }) => userProfile.email === email,
  target: resendEmailConfirmationRequest,
})

// update profile

sample({
  source: $userProfile,
  clock: submitEmailVerifyRepeatForm,
  fn: (userProfile, { email }) => {
    userProfile.email = email
    return userProfile
  },
  target: $userProfile,
})

// redirect to confirm in case of success

sample({
  clock: emailConfirmationRequest.done,
  fn: () => path.register.email.confirm(),
  target: toPath,
})

// handle errors

export const $error = createStore<ClientError<
  Pick<RegisterByEmail, 'email'>
> | null>(null)

$error
  .on(emailConfirmationRequest.failData, (_, failData) =>
    mapResponseError<Pick<RegisterByEmail, 'email'>>(failData.body),
  )
  .reset(submitEmailVerifyRepeatForm)
