import { AccountSelect } from 'pages/money/molecules/newAccountSelect'
import styled from 'styled-components/macro'
import { Button, Input } from 'ui/atoms'

export const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;

  @media (max-width: 599px) {
    margin-top: 12px;
    font-size: var(--body-font-size-regular);
    line-height: var(--body-line-height-regular);
  }
`

export const InternalTransferWrapper = styled.div`
  margin-top: 32px;
`

export const SourceSelect = styled(AccountSelect)`
  margin-top: 20px;
  margin-bottom: 16px;
  max-width: 100%;

  @media (max-width: 599px) {
    margin-bottom: 12px;
  }
`

export const AmountInput = styled(Input)`
  max-width: 331px;

  @media (max-width: 599px) {
    max-width: 100%;
  }
`

export const InternalTransferButton = styled(Button)`
  margin-top: 20px;
  margin-bottom: 32px;

  @media (max-width: 599px) {
    margin-bottom: 72px;
    width: 100%;
  }
`

export const CalculatedAmount = styled.div`
  margin: 20px 0;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
  color: var(--color-gray-600);
`