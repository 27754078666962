import { useWindowSize } from '@uidotdev/usehooks'
import { formatDuration, intervalToDuration } from 'date-fns'
import { useStore } from 'effector-react'
import { path } from 'libs/path'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import VerificationInput from 'react-verification-input'
import { service } from 'services'
import styled from 'styled-components/macro'
import { Button, Icon2 } from 'ui/atoms'

import { AuthLayout, AuthSectionTitle } from '../../../layout'
import * as Styled from '../../../register/emailConfirm/styled'
import { usePasswordRecoveryConfirmPage } from './hooks'
import {
  $resendCounter,
  $resendTimer,
  $timerInitial,
  submitForm,
  submitRepeat,
} from './model'

export const PasswordRecoveryConfirm = () => {
  const [t] = useTranslation()

  const navigate = useNavigate()

  const resendCounter = useStore($resendCounter)
  const resendCodeLoading = useStore(service.auth.postResetPassword.pending)

  const { userProfile, timer, loading, error } =
    usePasswordRecoveryConfirmPage()

  return (
    <AuthLayout type="reset">
      <AuthSectionTitle level={3}>Password recovery</AuthSectionTitle>
      <div className="mt-[8px] text-body.regular leading-body.regular">
        <Trans
          i18nKey="The verification code is sent to"
          values={{ email: userProfile.email }}
          components={[<span />, <span className="font-medium" />]}
        />
      </div>
      <div className="mt-[8px] text-body.regular leading-body.regular">
        {t(
          'Enter the 6-digit code below or click the link in the email. The code is valid for 30 minutes.',
        )}
      </div>

      <Styled.InputWrapper hasError={!!error?.fields?.code}>
        <VerificationInput
          autoFocus
          placeholder="0"
          validChars="0-9"
          onComplete={(code) => submitForm({ code })}
          inputProps={{ inputMode: 'numeric' }}
        />
      </Styled.InputWrapper>

      {!!error?.fields?.code && (
        <div className="mt-[10px] text-body.medium leading-body.medium text-opal.600">
          {error.fields.code}
        </div>
      )}

      <ResendTimer isLoading={loading} />

      {timer === 0 && resendCounter > 0 && !resendCodeLoading && (
        <ResendCodeButton
          name="navigate-register-verificate-repeat"
          size="small"
          design="tertiary-brand"
          onClick={() => {
            navigate(path.register.password.recovery.confirmRepeat())
          }}
        >
          Didn’t receive the Code?
        </ResendCodeButton>
      )}
    </AuthLayout>
  )
}

const ResendTimer = ({ isLoading }: { isLoading: boolean }) => {
  const [t] = useTranslation()

  const { width } = useWindowSize()
  const isMobile = width && width < 600

  const secondsLeft = useStore($resendTimer)
  const timerInitial = useStore($timerInitial)
  const isRunning = Boolean(secondsLeft) && secondsLeft <= timerInitial

  const formattedDuration = formatDuration(
    intervalToDuration({ start: 0, end: secondsLeft * 1000 }),
    {
      format: ['minutes', 'seconds'],
      zero: true,
      delimiter: ':',
      locale: {
        formatDistance: (_token, count) => String(count).padStart(2, '0'),
      },
    },
  )

  return (
    <Styled.ResendButton
      design={isRunning || isLoading ? 'tertiary' : 'secondary'}
      disabled={isRunning || isLoading}
      size={isMobile ? 'medium' : 'large'}
      prefix={isRunning ? <Icon2 name="clock" /> : null}
      loading={isLoading}
      onClick={
        isRunning
          ? undefined
          : () => {
              submitRepeat()
            }
      }
    >
      {isRunning ? (
        <Trans
          t={t}
          i18nKey="Resend code in"
          values={{ time: formattedDuration }}
          defaults="Resend code in {{ time }}"
        />
      ) : (
        'Resend code'
      )}
    </Styled.ResendButton>
  )
}

const ResendCodeButton = styled(Button)`
  width: 100%;
  margin-top: 16px;
`
