import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useWindowSize } from 'usehooks-ts';
import { addDays, format } from 'date-fns';

import { toUnixTimeString } from 'libs/dateUtils';
import { Button, DatePicker, Icon2, Select } from 'ui/atoms';

import { periods } from './utils';

import * as S from './styled';
import { SelectWithModal } from 'ui/atoms/select/SelectWithModal';

export type SetTimePropType = {
  from: string;
  to: string;
}

export type DatePeriods = 'all' | 'custom' | string;
export type CalendarPostion = 'left' | 'right';

interface Props {
  className?: string;
  optionsListClasses?: string;
  inputClasses?: string;
  position?: CalendarPostion;
  label?: string;
  values?: SetTimePropType & {
    period?: string;
    from: Date;
    to: Date;
  };
  hideOptions?: boolean;
  isSheetMobile?: boolean;
  setTimeline: (e: SetTimePropType,  period?: DatePeriods) => void;
  customMapperDates?: (val: Date) => string;
}

export const DateRange: React.FC<Props> = ({ 
  setTimeline, customMapperDates, className, label, values,
  inputClasses, optionsListClasses, isSheetMobile, hideOptions,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [dateSelectValue, setDateSelectValue] = useState(values?.period || '');
  
  const SelectComp = isSheetMobile ? SelectWithModal : Select;

  const { width } = useWindowSize();
  const isMobileMode = width < 600;

  const resetDates = () => {
    setStartDate(null);
    setEndDate(null);
    setDateSelectValue('all');
  };

  // Принимает параметры startDateValue и endDateValue, чтобы не указывать их в зависимости в кэшировании
  const getRangeDate = useCallback((startDateValue: Date | null, endDateValue: Date | null) => {
    if (!startDateValue || !endDateValue) return '';
  
    return `${format(startDateValue, 'dd/MM/yyyy')}-${format(endDateValue, 'dd/MM/yyyy')}`
  }, []);

  const getOptions = useMemo(() => {
    const customPeriodValue = {
      label: 'Custom date',
      value: 'custom',
      hidden: true
    };

    const isPreseteriod = periods.some(val => val.value === dateSelectValue);

    if (!isPreseteriod && startDate && endDate) {
      const customValue = getRangeDate(startDate, endDate);
      customPeriodValue.label = customValue;
      customPeriodValue.value = customValue;
    }

    if (hideOptions) return [customPeriodValue];

    const options = [...periods, customPeriodValue];
    return options;
  }, [dateSelectValue, endDate, isMobileMode, startDate, hideOptions]);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates

    setStartDate(start)
    setEndDate(end)
  }

  const changeDates = () => {
    if (!startDate || !endDate) return;

    const selectValue = getRangeDate(startDate, endDate);

    setDateSelectValue(selectValue);
    const mapper = customMapperDates || toUnixTimeString;
    
    setTimeline({
      from: mapper(startDate as Date),
      to: mapper(endDate as Date)
    }, selectValue);
  }

  const onSelectRange = (period: string) => {
    if (!period) return;
    
    setStartDate(null);
    setEndDate(null);
    setDateSelectValue(period);

    const mapper = customMapperDates || toUnixTimeString;

    setTimeline({
      from: period === 'all' ? '0' : mapper(addDays(new Date(), -period)),
      to: mapper(new Date()),
    }, period);
  }

  useEffect(() => {
    if (!values) return;

    const isPreseteriod = periods.some(val => val.value === dateSelectValue);
    
    setDateSelectValue(values?.period || '');
    if (isPreseteriod) {
      return;
    }
    setStartDate(values.from);
    setEndDate(values.to);
  }, [values]);

  return (
    <>
      <SelectComp
        value={dateSelectValue}
        placeholder="Timeframe"
        className={`${className}`}
        inputMode='none'
        label={label}
        autoComplete="off"
        inputClasses={ inputClasses }
        listClassName={ optionsListClasses }
        options={getOptions}
        modalTitle='Timeframe'
        size='base'
        // @ts-ignore
        prefix={<Icon2 name='calendar' size='base' />}
        onSelect={onSelectRange}
        optionsWidth={isMobileMode ? undefined : 375}
        
        maxHeight={690}
        customContent={
          <S.DatePickerWrapper hideTopline={hideOptions}>
            <DatePicker
              selected={startDate}
              // @ts-expect-error
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              // @ts-expect-error
              selectsRange
            />
            {startDate && endDate && (
              <S.PickerButtons>
                <Button
                  name="cancel"
                  size="medium"
                  design="tertiary"
                  onClick={resetDates}
                >
                  Cancel
                </Button>
                <Button name="cancel" size="medium" onClick={changeDates}>
                  Select
                </Button>
              </S.PickerButtons>
            )}
          </S.DatePickerWrapper>
        }
      />
    </>
  )
}
