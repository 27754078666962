import { useWindowSize } from '@uidotdev/usehooks'
import { useStore } from 'effector-react'
import { $userProfile } from 'features/user'
import { path } from 'libs/path'
import { Account } from 'pages/accounts/types'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  createSearchParams,
  useBlocker,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { useGetTariffsLimits } from 'services/accounts/get/useGetTariffsLimits'
import { useAddAccount } from 'services/accounts/mutation/useAddAccount'
import { useStartCopying } from 'services/copytrade'
import { AccountSourceItem } from 'services/payments/get/types'
import { Icon2, Title } from 'ui/atoms'
import { Alerts } from 'ui/molecules'
import { TabContent } from 'ui/molecules/tabs'

import {
  $accounts,
  $copyingSettings,
  $copyingStrategy,
  $keepStrategyUntil,
  $newAccountForCopying,
  $paymentAvailabilityParams,
  resetCopyingSettings,
  resetCopyingStrategy,
  setAccountCreationAlertVisible,
  setCopyOpenedPos,
  setCopyingStrategyID,
  setCopyingStrategyProportion,
  setInvestorAccountID,
  setKeepStrategyUntil,
  setCurrentStep as setModelCurrentStep,
  setNewAccountForCopying,
  setStartCopyingAlertVisible,
  updateStartCopyingAccounts,
} from '../model'
import { AccountLine } from '../molecules/accountLine'
import { AgreementCheckbox } from '../molecules/agreementCheckbox'
import { BackButton } from '../molecules/backButton'
import { DepositAccount } from '../molecules/depositAccount'
import { LeavePageModal } from '../molecules/leavePageModal'
import { ProportionForm } from '../molecules/proportionForm'
import { StrategyAccountInfo } from '../molecules/strategyAccountInfo'
import * as Styled from './styled'
import { getSourceItems, steps } from './utils'

export const StartCopying = () => {
  const [t] = useTranslation()

  const { width } = useWindowSize()
  const isMobileMode = width && width < 600

  const { userID } = useStore($userProfile)

  const accounts = useStore($accounts)

  const copyingStrategy = useStore($copyingStrategy)
  const copyingSettings = useStore($copyingSettings)
  const keepStrategyUntil = useStore($keepStrategyUntil)

  const paymentAvailabilityParams = useStore($paymentAvailabilityParams)

  // копия счета трейдера, либо новый счет, созданный через флоу создания
  const newAccountForCopying = useStore($newAccountForCopying)

  const navigate = useNavigate()
  const [_, setSearchParams] = useSearchParams()
  const { pathname } = useLocation()
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      [
        path.copytrade.startCopying.selectAccount(),
        path.copytrade.startCopying.accountSettings(),
        path.copytrade.startCopying.replenishAccount(),
      ].some((path) => path === currentLocation.pathname) &&
      !!copyingStrategy &&
      [
        path.accounts.create.tariff('real'),
        path.copytrade.startCopying.selectAccount(),
        path.copytrade.startCopying.accountSettings(),
        path.copytrade.startCopying.replenishAccount(),
        path.copytrade.strategyForInvestor(copyingStrategy.strategyID),
      ].every((path) => path !== nextLocation.pathname)
    )
  })

  const handleLeave = () => {
    resetCopyingStrategy()
    resetCopyingSettings()

    blocker.proceed?.()

    document.body.style.overflow = 'visible'
  }

  const [currentStep, setCurrentStep] = useState(steps[0])

  const [isDuplicatedAccount, setIsDuplicatedAccount] = useState(false)

  // уже существующий счёт
  const [selectedAccount, setSelectedAccount] = useState<AccountSourceItem>()

  // счет трейдера; небольшой костыль, т.к. StrategyAccountInfo - отдельный компонент
  // и запрос счета трейдера там внутри
  const [duplicatedAccountData, setDuplicatedAccountData] = useState<Account>()

  const [agreementChecked, setAgreementChecked] = useState(false)

  const {
    data: addedAccount,
    mutateAsync: addAccount,
    isLoading: addAccountLoading,
  } = useAddAccount()
  const { data: tariffsLimits } = useGetTariffsLimits('PAAT_REAL', !!userID)
  const { mutateAsync: startCopying, isLoading: isStartCopyingLoading } =
    useStartCopying()

  // существующий или новый
  const currentAccount =
    accounts.find(
      (account) => account.accountID === copyingSettings.investorAccountID,
    ) || addedAccount

  const source = getSourceItems(accounts)

  const isDuplicateDisabled =
    tariffsLimits?.availableByTariffs.find(
      (tariff) =>
        duplicatedAccountData &&
        duplicatedAccountData.platform === tariff.accountPlatform &&
        duplicatedAccountData.tariffName === tariff.name,
    )?.numAvailable === 0

  const detailsStepDisabled =
    (!isDuplicatedAccount && !selectedAccount) ||
    (isDuplicatedAccount && isDuplicateDisabled)
  const replenishmentStepDisabled = !agreementChecked

  const handleSetDetails = async () => {
    if (isDuplicatedAccount && duplicatedAccountData) {
      await addAccount(duplicatedAccountData)

      setAccountCreationAlertVisible(true)

      setTimeout(() => {
        setAccountCreationAlertVisible(false)
      }, 10000)
    }

    navigate(path.copytrade.startCopying.accountSettings())
    setCurrentStep('Details')
  }

  const handleChangeAccount = () => {
    navigate(path.copytrade.startCopying.selectAccount())
    setCurrentStep('Account')
  }

  const handleReplenish = () => {
    navigate(path.copytrade.startCopying.replenishAccount())

    setCurrentStep('Depositing')
  }

  const handleStartCopying = async () => {
    await startCopying(copyingSettings)

    setStartCopyingAlertVisible(true)

    navigate({
      pathname: path.copytrade.strategyForInvestor(copyingSettings.strategyID),
      search: createSearchParams({ copy_success: 'true' }).toString(),
    })

    setTimeout(() => {
      setStartCopyingAlertVisible(false)

      setSearchParams('')
    }, 10000)
  }

  useEffect(() => {
    updateStartCopyingAccounts()
  }, [])

  useEffect(() => {
    setModelCurrentStep(currentStep)

    if (
      currentStep === 'Depositing' &&
      (!keepStrategyUntil || keepStrategyUntil < new Date().getTime())
    ) {
      // сохраняем стратегию на 2 недели
      setKeepStrategyUntil(
        // +new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000),
        +new Date(new Date().getTime() + 2 * 60 * 1000), // 2 мин для теста
      )
    }
  }, [currentStep])

  useEffect(() => {
    switch (pathname) {
      case path.copytrade.startCopying.accountSettings(): {
        setCurrentStep('Details')
        break
      }
      case path.copytrade.startCopying.replenishAccount(): {
        setCurrentStep('Depositing')
        break
      }
      default: {
        setCurrentStep('Account')
      }
    }
  }, [pathname])

  useEffect(() => {
    if (copyingStrategy) {
      setCopyingStrategyID(copyingStrategy.strategyID)
    }
  }, [copyingStrategy])

  useEffect(() => {
    if (isDuplicatedAccount && addedAccount) {
      setInvestorAccountID(addedAccount.accountID)
    } else if (!isDuplicatedAccount && selectedAccount) {
      setInvestorAccountID(selectedAccount.id)
    }
  }, [selectedAccount, addedAccount, isDuplicatedAccount])

  useEffect(() => {
    if (addedAccount) {
      setNewAccountForCopying(addedAccount)
    }
  }, [addedAccount])

  useEffect(() => {
    if (selectedAccount) {
      setNewAccountForCopying(null)
    }
  }, [selectedAccount])

  return (
    <TabContent>
      <BackButton to={path.copytrade.investorEntry()} />
      <Styled.ContentContainer>
        <Title level={isMobileMode ? 3 : 2}>Start copying</Title>
        {copyingStrategy ? (
          <>
            <Styled.CopyingInfo>
              {t(
                'Choose an account and customize copying settings to start right away.',
              )}
            </Styled.CopyingInfo>
            <Styled.Steps>
              {steps.map((step, index) => (
                <Styled.Step
                  key={index}
                  isActive={currentStep === step}
                  isChecked={steps.indexOf(currentStep) > index}
                >
                  {t(step)}
                </Styled.Step>
              ))}
            </Styled.Steps>

            {currentStep === 'Account' && (
              <>
                <Styled.AccountRadioWrapper>
                  <Styled.Radio
                    isActive={!isDuplicatedAccount}
                    onClick={() => setIsDuplicatedAccount(false)}
                  >
                    {t('Choose your account')}
                  </Styled.Radio>
                  <Styled.AccountSelect
                    placeholder="Select account"
                    data={source}
                    onSelect={setSelectedAccount}
                    createAccountEnabled={
                      tariffsLimits?.isPossibleCreateAccount
                    }
                  />
                </Styled.AccountRadioWrapper>
                <Styled.AccountRadioWrapper>
                  <Styled.Radio
                    isActive={isDuplicatedAccount}
                    isDisabled={isDuplicateDisabled}
                    onClick={() => setIsDuplicatedAccount(true)}
                  >
                    {t('Create new account and copy strategy settings')}
                  </Styled.Radio>
                  <StrategyAccountInfo
                    strategyID={copyingStrategy.strategyID}
                    onGetData={setDuplicatedAccountData}
                    isDisabled={isDuplicateDisabled}
                  />
                  {isDuplicatedAccount && !isDuplicateDisabled && (
                    <Styled.WarningText>
                      <Trans
                        i18nKey="If you and the Trader live in different countries, some strategy features may not be available to copy. This includes but is not limited to Swap-Free and Fix Rate. If the leverage cannot be copied due to clause 4.7 of the Headway Client Agreement, it will be set to the highest possible."
                        t={t}
                      >
                        If you and the Trader live in different countries, some
                        strategy features may not be available to copy. This
                        includes but is not limited to Swap-Free and Fix Rate.
                        If the leverage cannot be copied due to{' '}
                        <a
                          href={path.terms()}
                          target="_blank"
                          className="font-medium text-bronze.500"
                          rel="noreferrer"
                        >
                          clause 4.7
                        </a>{' '}
                        of the Headway Client Agreement, it will be set to the
                        highest possible.
                      </Trans>
                    </Styled.WarningText>
                  )}
                </Styled.AccountRadioWrapper>
                <Styled.StepsButtons>
                  <Styled.ChangeStepButton
                    name="set-details"
                    suffix={<Icon2 name="arrowRight" />}
                    onClick={handleSetDetails}
                    disabled={addAccountLoading || detailsStepDisabled}
                    loading={addAccountLoading}
                    size={isMobileMode ? 'medium' : 'large'}
                  >
                    Set details
                  </Styled.ChangeStepButton>
                </Styled.StepsButtons>
              </>
            )}

            {currentStep === 'Details' && (
              <Styled.GridColumn>
                {newAccountForCopying && (
                  <>
                    <div>
                      <Title level={isMobileMode ? 4 : 3}>
                        Your new account
                      </Title>
                      <Styled.DuplicatedAccountLine
                        account={newAccountForCopying}
                      />
                    </div>
                    <div>
                      <Title level={isMobileMode ? 4 : 3}>
                        MetaTrader settings
                      </Title>
                      <Styled.MetaTraderInfo
                        login={newAccountForCopying.login}
                        password={newAccountForCopying.password}
                        mtServerInfo={newAccountForCopying.mtServerInfo}
                        accountID={newAccountForCopying.accountID}
                      />
                    </div>
                  </>
                )}
                <div>
                  <Title level={isMobileMode ? 4 : 3}>Choose proportion</Title>
                  <Styled.ProportionText>
                    {t(
                      "Compared to 1 standard lot of the strategy's trading volume.",
                    )}
                  </Styled.ProportionText>
                  <Styled.ProportionWrapper>
                    <ProportionForm
                      onChange={setCopyingStrategyProportion}
                      initialValue={copyingSettings.proportion}
                    />
                  </Styled.ProportionWrapper>
                </div>
                <div>
                  <Title level={isMobileMode ? 4 : 3}>Copying mode</Title>
                  <Styled.CopyingRadioWrapper>
                    <Styled.Radio
                      isActive={copyingSettings.isCopyOpenedPos}
                      onClick={() => setCopyOpenedPos(true)}
                    >
                      {t('Copy all orders including open positions')}
                    </Styled.Radio>
                    <Styled.Radio
                      isActive={!copyingSettings.isCopyOpenedPos}
                      onClick={() => setCopyOpenedPos(false)}
                    >
                      {t('Copy new orders only')}
                    </Styled.Radio>
                  </Styled.CopyingRadioWrapper>
                </div>
                <AgreementCheckbox
                  checked={agreementChecked}
                  onChange={setAgreementChecked}
                />
                <Styled.StepsButtons>
                  <Styled.ChangeStepButton
                    name="change-account"
                    design="secondary"
                    prefix={<Icon2 name="arrowLeft" />}
                    onClick={handleChangeAccount}
                    size={isMobileMode ? 'medium' : 'large'}
                  >
                    Change account
                  </Styled.ChangeStepButton>
                  <Styled.ChangeStepButton
                    name="replenish"
                    suffix={<Icon2 name="arrowRight" />}
                    onClick={handleReplenish}
                    disabled={replenishmentStepDisabled}
                    size={isMobileMode ? 'medium' : 'large'}
                  >
                    Deposit
                  </Styled.ChangeStepButton>
                </Styled.StepsButtons>
              </Styled.GridColumn>
            )}

            {currentStep === 'Depositing' && (
              <>
                <Styled.GridColumn>
                  {paymentAvailabilityParams && (
                    <div className="max-w-[580px]">
                      <Alerts type="info" title="Commission for copying">
                        <Trans i18nKey="The strategy has a monthly commission of $30. To start copying it, you need to pay $15.05 for the remaining days of this month.">
                          The strategy has a monthly commission of{' '}
                          {{
                            commission: `$${paymentAvailabilityParams.commission}`,
                          }}
                          . To start copying it, you need to pay{' '}
                          {{
                            amount: `$${paymentAvailabilityParams.srcAmount}${
                              paymentAvailabilityParams.dstCurrency !== 'USD' ||
                              paymentAvailabilityParams.dstIsFixRate
                                ? ` (${paymentAvailabilityParams.dstAmount} ${
                                    paymentAvailabilityParams.dstCurrency
                                  }${
                                    paymentAvailabilityParams.dstIsFixRate
                                      ? ' Fix rate'
                                      : ''
                                  })`
                                : ''
                            }`,
                          }}{' '}
                          for the remaining days of this month.
                        </Trans>
                      </Alerts>
                    </div>
                  )}
                  {currentAccount && (
                    <Styled.DepositInfo>
                      <AccountLine account={currentAccount} />

                      {!paymentAvailabilityParams?.isAvailable && (
                        <DepositAccount currentAccount={currentAccount} />
                      )}
                    </Styled.DepositInfo>
                  )}
                </Styled.GridColumn>
                <Styled.StepsButtons>
                  <Styled.ChangeStepButton
                    name="change-account"
                    design="secondary"
                    prefix={<Icon2 name="arrowLeft" />}
                    onClick={handleSetDetails}
                    size={isMobileMode ? 'medium' : 'large'}
                  >
                    Change details
                  </Styled.ChangeStepButton>
                  <Styled.ChangeStepButton
                    name="set-details"
                    disabled={
                      !paymentAvailabilityParams?.isAvailable ||
                      isStartCopyingLoading
                    }
                    loading={isStartCopyingLoading}
                    onClick={handleStartCopying}
                    size={isMobileMode ? 'medium' : 'large'}
                  >
                    Start copying
                  </Styled.ChangeStepButton>
                </Styled.StepsButtons>
              </>
            )}

            <LeavePageModal
              isOpen={blocker.state === 'blocked'}
              onOk={handleLeave}
              onCancel={() => blocker.reset?.()}
            />
          </>
        ) : (
          <div className="mt-[16px]">not found</div>
        )}
      </Styled.ContentContainer>
    </TabContent>
  )
}
