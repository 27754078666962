import { useMutation } from '@tanstack/react-query'
import { axios } from 'services/axios'

const register = async (contestName: string, team: string) => {
  await axios.post<{}>('/demo-contests/v1/contests/register', {
    contestName,
    team,
  })
}

export const useRegister = (contestName: string) =>
  useMutation({
    mutationFn: (team: string) => register(contestName, team),
  })
