import styled, { css } from 'styled-components'
import { Title as UiTitle } from 'ui/atoms'

export const Container = styled.div`
  margin: 56px 0;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 599px) {
    margin: 40px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const Title = styled(UiTitle)`
  margin-bottom: 24px;
  text-align: center;
`

export const TableHead = styled.div`
  height: 48px;
  display: grid;
  grid-template-columns: 70px 200px 1fr 1fr 1fr;
  align-items: center;
  text-align: center;
  color: var(--color-gray-600);
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  background-color: var(--color-gray-300);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`

export const TableRow = styled.div<{ selected: boolean }>`
  border: 1px solid var(--color-gray-300);
  border-top: none;
  min-height: 88px;
  display: grid;
  grid-template-columns: 70px 200px 1fr 1fr 1fr;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);

  @media (max-width: 799px) {
    padding: 16px 12px;
    grid-template-columns: 36px 160px 1fr;
    gap: 12px 4px;

    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: var(--color-gray-300);

      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    `}
`

export const PlaceWrapper = styled.div`
  display: grid;
  place-items: center;

  @media (max-width: 799px) {
    grid-row: span 2;
    place-items: start;
  }

  img {
    width: 48px;
    height: auto;

    @media (max-width: 799px) {
      min-width: 48px;
      max-width: 48px;
      margin-top: 6px;
      margin-inline-start: -8px;
    }
  }
`

export const NameWrapper = styled.div`
  padding-top: 7px;
  padding-bottom: 7px;
  padding-inline-start: 16px;
  display: grid;
  gap: 8px;
  align-content: center;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  font-weight: 500;

  @media (max-width: 799px) {
    padding-bottom: 6px;
    padding-inline-start: 0;
    gap: 4px;
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const TeamWrapper = styled.div`
  display: grid;
  place-items: center;

  @media (max-width: 799px) {
    grid-column: 3;
    grid-row: 2;
    place-items: start;
  }

  img {
    width: 40px;
    height: auto;

    @media (max-width: 799px) {
      width: 32px;
    }
  }
`

export const RegularCell = styled.div`
  display: grid;
  place-items: center;

  @media (max-width: 799px) {
    place-items: start;
    align-content: start;
    line-height: 32px;
  }

  &:last-child {
    font-weight: 500;
    color: var(--color-eucalyptus-700);

    @media (max-width: 799px) {
      grid-row: 1;
      grid-column: 3;
    }
  }
`

export const CellTitle = styled.div`
  margin-bottom: 4px;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
  color: var(--color-gray-600);
  font-weight: normal;
`

export const TopTraders = styled.div`
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-regular);
  color: var(--color-sunray-700);
  font-weight: 500;
`
