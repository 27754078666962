import 'swiper/css'
import 'swiper/css/navigation'

// todo: заменить usehooks-ts на @uidotdev/usehooks
import { useMeasure, useWindowSize } from '@uidotdev/usehooks'
import { useStore } from 'effector-react'
import { $userProfile } from 'features/user'
import { path } from 'libs/path'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Strategy } from 'services/copytrade/types'
import { Mousewheel, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Button, Icon, Text, Title } from 'ui/atoms'
import { toCurrency } from 'ui/atoms/money'
import { mapAccountPlatform, mapAccountTariff } from 'ui/molecules/accountLines'
import { Modal } from 'ui/molecules/modal_draft'
import { TabContent } from 'ui/molecules/tabs'
import { Arrows } from 'ui/molecules/widgetSlider/styled'

import {
  $strategiesComparisonList,
  removeStrategyFromCompare,
  resetCopyingSettings,
  resetCopyingStrategy,
  setCopyingStrategy,
} from '../model'
import { BackButton } from '../molecules/backButton'
import { RiskLabel } from '../molecules/riskLabel'
import { formatNumber, getNumberColor } from '../utils'
import * as Styled from './styled'
import { findLongestIndex } from './utils'

export const CompareStrategies = () => {
  const [t] = useTranslation()

  const navigate = useNavigate()

  const [isStartCopyingModalVisible, setIsStartCopyingModalVisible] =
    useState(false)

  const { userID } = useStore($userProfile)
  const strategiesComparisonList = useStore($strategiesComparisonList)

  const longestTitleIndex = findLongestIndex(
    strategiesComparisonList.map((strategy) => strategy.strategyTitle),
  )

  const [ref, { height }] = useMeasure()

  const { width } = useWindowSize()
  const isMobileMode = width && width < 600

  const handleStartCopying = (data: Strategy) => {
    if (!data) {
      return
    }

    if (+data.balance <= 0.1) {
      setIsStartCopyingModalVisible(true)
    } else {
      resetCopyingStrategy()
      resetCopyingSettings()

      setCopyingStrategy(data)

      navigate(path.copytrade.startCopying.selectAccount())
    }
  }

  return (
    <TabContent>
      <BackButton to={path.copytrade.investorEntry()} />
      <Styled.ContentContainer>
        <Styled.TopWrapper>
          <Title level={isMobileMode ? 3 : 2}>Compare strategies</Title>
          <Styled.AddButton
            name="add-to-compare"
            design="secondary"
            size={isMobileMode ? 'small' : 'medium'}
            prefix={
              <Icon name="plus" size={isMobileMode ? 'small' : 'medium'} />
            }
            onClick={() => navigate(path.copytrade.investorEntry())}
          >
            Add to compare
          </Styled.AddButton>
        </Styled.TopWrapper>
        <Styled.SliderWrapper>
          <Styled.LeftColumn>
            <Styled.RowTitle height={height} isLarge>
              {t('Name')}
            </Styled.RowTitle>
            <Styled.RowTitle>{t('Commission')}</Styled.RowTitle>
            <Styled.RowTitle>{t('Gross profit')}</Styled.RowTitle>
            <Styled.RowTitle>{t('Gain')}</Styled.RowTitle>
            <Styled.RowTitle>{t('Active days')}</Styled.RowTitle>
            <Styled.RowTitle>{t('Win rate')}</Styled.RowTitle>
            <Styled.RowTitle>{t('Risk')}</Styled.RowTitle>
            <Styled.RowTitle>{t('Investors')}</Styled.RowTitle>
            <Styled.RowTitle>{t('Profitable orders')}</Styled.RowTitle>
            <Styled.RowTitle>{t('Profit')}</Styled.RowTitle>
            <Styled.RowTitle>{t('Loss orders')}</Styled.RowTitle>
            <Styled.RowTitle>{t('Loss')}</Styled.RowTitle>
            <Styled.RowTitle>{t('Balance')}</Styled.RowTitle>
            <Styled.RowTitle>{t('Equity')}</Styled.RowTitle>
            <Styled.RowTitle>{t('Account type')}</Styled.RowTitle>
            <Styled.RowTitle>{t('Platform')}</Styled.RowTitle>
            <Styled.RowTitle>{t('Leverage')}</Styled.RowTitle>
            <Styled.RowTitle>{t('Currency')}</Styled.RowTitle>
            <Styled.RowTitle>{t('Joining date')}</Styled.RowTitle>
            <Styled.RowTitle>{t('Favored')}</Styled.RowTitle>
            <Styled.RowTitle>{t('Likes')}</Styled.RowTitle>
          </Styled.LeftColumn>
          <Swiper
            slidesPerView="auto"
            grabCursor
            mousewheel={{ forceToAxis: true }}
            modules={[Navigation, Mousewheel]}
            navigation={{
              prevEl: '.prev-slider-button',
              nextEl: '.next-slider-button',
            }}
            className="!flex flex-col items-end"
          >
            {strategiesComparisonList.map((strategy, index) => (
              <SwiperSlide
                style={{ width: isMobileMode ? 107 : 180 }}
                key={strategy.strategyID}
              >
                <Styled.TopInfo
                  ref={index === longestTitleIndex ? ref : null}
                  style={{ height: `${height}px` }}
                >
                  <Styled.CloseButton
                    onClick={() =>
                      removeStrategyFromCompare({
                        strategyID: strategy.strategyID,
                        userID,
                      })
                    }
                  />
                  <Styled.StrategyImageWrapper>
                    <img
                      src={strategy.strategyImageLink}
                      alt={strategy.strategyTitle}
                    />
                  </Styled.StrategyImageWrapper>
                  <Styled.StrategyTitle>
                    {strategy.strategyTitle}
                  </Styled.StrategyTitle>
                  <Styled.CopyingButton
                    name="start-sopying"
                    prefix={<Icon name="arrowsDiagonal" size="small" />}
                    size="small"
                    onClick={() => handleStartCopying(strategy)}
                  >
                    {isMobileMode ? 'Copy' : 'Start copying'}
                  </Styled.CopyingButton>
                </Styled.TopInfo>
                <Styled.RowValue>
                  ${formatNumber(strategy.commission)}
                </Styled.RowValue>
                <Styled.RowValue color={getNumberColor(strategy.grossProfit)}>
                  {+strategy.grossProfit < 0
                    ? '-'
                    : +strategy.grossProfit > 0
                    ? '+'
                    : ''}
                  ${formatNumber(Math.abs(+strategy.grossProfit))}
                </Styled.RowValue>
                <Styled.RowValue color={getNumberColor(strategy.gain)}>
                  {+strategy.gain > 0 ? '+' : ''}
                  {strategy.gain}%
                </Styled.RowValue>
                <Styled.RowValue>{strategy.activeDays}</Styled.RowValue>
                <Styled.RowValue>{strategy.winRate}%</Styled.RowValue>
                <Styled.RowValue>
                  <RiskLabel
                    risk={strategy.risk}
                    size={isMobileMode ? 'small' : 'medium'}
                    isShortLabel
                  />
                </Styled.RowValue>
                <Styled.RowValue>{strategy.numInvestors}</Styled.RowValue>
                <Styled.RowValue>
                  {strategy.numProfitableOrders}
                </Styled.RowValue>
                <Styled.RowValue>
                  ${formatNumber(strategy.profit)}
                </Styled.RowValue>
                <Styled.RowValue>{strategy.numLossOrders}</Styled.RowValue>
                <Styled.RowValue>
                  {+strategy.loss < 0 ? '-' : ''}$
                  {formatNumber(Math.abs(+strategy.loss))}
                </Styled.RowValue>
                <Styled.RowValue>
                  {toCurrency(+strategy.balance, strategy.currency)}
                </Styled.RowValue>
                <Styled.RowValue>
                  {toCurrency(+strategy.equity, strategy.currency)}
                </Styled.RowValue>
                <Styled.RowValue>
                  {mapAccountTariff[strategy.tariffName]}
                </Styled.RowValue>
                <Styled.RowValue>
                  {mapAccountPlatform[strategy.platform]}
                </Styled.RowValue>
                <Styled.RowValue>
                  {strategy.leverage === '210000000'
                    ? t('Unlimited')
                    : `1:${strategy.leverage}`}
                </Styled.RowValue>
                <Styled.RowValue>{strategy.currency}</Styled.RowValue>
                <Styled.RowValue>{strategy.joiningDate}</Styled.RowValue>
                <Styled.RowValue>{strategy.numFavorites}</Styled.RowValue>
                <Styled.RowValue>{strategy.numLikes}</Styled.RowValue>
              </SwiperSlide>
            ))}
            {!isMobileMode && strategiesComparisonList.length > 5 && (
              <Arrows>
                <Button
                  name="swiper-prev"
                  className="prev-slider-button"
                  design="secondary"
                  size="small"
                />
                <Button
                  name="swiper-next"
                  className="next-slider-button"
                  design="secondary"
                  size="small"
                />
              </Arrows>
            )}
          </Swiper>
        </Styled.SliderWrapper>

        <Modal
          title="Copying is currently unavailable"
          open={isStartCopyingModalVisible}
          onClose={() => setIsStartCopyingModalVisible(false)}
        >
          <Text className="!text-gray.600">
            The strategy balance is below the minimum required for adding new
            investors. Please try again later.
          </Text>
        </Modal>
      </Styled.ContentContainer>
    </TabContent>
  )
}
