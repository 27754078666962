import { genericDomain } from 'features/domain'
import i18n from 'i18next'
import { getCookie } from 'libs/cookie'
import { setGlobalError } from 'ui/templates/generic/model'

import { env } from './constants'

export type Request<Payload> = {
  url?: string
  method?: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH'
  body?: Payload
  headers?: Record<string, string>
  query?: Record<string, string>
  cookies?: string
  formData?: Payload
}

export type Response<Result> = {
  ok: boolean
  body: Result
  status: number
  headers: Record<string, string>
}

export type ResponseError<Result = object> = {
  code: number
  msg: string
  details?: {
    code: number
    params: { [key: string]: string }
    msg: string
    validationErrors: {
      items: {
        msg: string
        field: keyof Result
        tKey: string
      }[]
    }
  }
}

export function fx<Payload, Result>(intitalParams: Request<Payload>) {
  const effect = genericDomain.createEffect<
    Request<Payload>,
    Response<Result>,
    Response<ResponseError<Payload>>
  >()

  effect.use((params) => {
    const props = {
      ...intitalParams,
      ...params,
    }

    return request(props)
  })

  return effect
}

export async function request<Response>(params: Request<unknown>) {
  const { url, method = 'GET', ...options } = params

  const formData = new FormData()
  const headers = new Headers(options.headers)

  const query = queryToString(options.query)

  setHeaders(headers)

  let requestBody: string | undefined | FormData = undefined

  if (options.body) {
    setHeaders(headers, 'application/json; charset=utf-8')
    requestBody = JSON.stringify(options.body)
  }

  if (options.formData) {
    appendFormData(options.formData, formData)
    requestBody = formData
  }

  const response = await fetch(`/api${url}${query}`, {
    method,
    body: requestBody,
    headers,
  })

  let responseBody: Response = {} as Response

  if (getContentType(response.headers, 'application/json')) {
    responseBody = await response.json()
  } else if (
    getContentType(response.headers, 'image/png') ||
    getContentType(response.headers, 'application/pdf')
  ) {
    const blob = await response.blob()

    const src = URL.createObjectURL(blob)

    responseBody = {
      src,
    } as Response
  } else {
    responseBody = (await response.text()) as Response
  }

  const responder = {
    url,
    ok: response.ok,
    body: responseBody,
    status: response.status,
    headers: toObject(response.headers),
  }

  if (response.ok) return responder

  if (
    response.status >= 500 ||
    (response.status === 404 &&
      // todo: как-нибудь нормально вытащить msg из ошибки здесь
      !(method === 'GET' && /\/verification$/.test(response.url)))
  ) {
    setGlobalError(true)

    setTimeout(() => {
      setGlobalError(false)
    }, 10000)
  }

  throw responder
}

function setHeaders(headers: Headers, type?: string): Headers {
  if (!headers.has('content-type')) {
    if (type) {
      headers.set('content-type', type)
    }

    const accessToken = localStorage.getItem('accessToken')?.replaceAll(`"`, '')

    const captchaToken = localStorage
      .getItem('captchaToken')
      ?.replaceAll(`"`, '')

    const hasAuthHeaders = headers.get('Authorization')

    if (!hasAuthHeaders) {
      headers.set('Authorization', `Bearer ${accessToken}`)
    }

    if (env.isDev) {
      headers.set('x-debug-country', 'IDN')
    }

    if (captchaToken) {
      headers.set('Grpc-Metadata-X-Recaptcha-Token', captchaToken)
    }
  }

  headers.set('X-Application-ID', '1')
  headers.set('X-Application-Platform', 'web')
  headers.set('X-Application-Product', 'headway')
  headers.set('X-Application-Web-Version', env.appVersion)
  headers.set('X-Analytics-Firebase-ID', getCookie('_ga')?.substring(6) || '')
  headers.set(
    'X-User-Language',
    i18n.language.slice(0, 2) || localStorage.i18nextLng.slice(0, 2) || 'en',
  )

  return headers
}

function queryToString(query: Record<string, string> | undefined): string {
  return Boolean(query) ? `?${new URLSearchParams(query).toString()}` : ''
}

function getContentType(headers: Headers, type: string): boolean {
  return headers.get('content-type')?.includes(type) ?? false
}

function toObject(headers: Headers): Record<string, string> {
  const target = {}
  headers.forEach((value, key) => {
    // @ts-ignore
    target[key] = value
  })
  return target
}

export function appendFormData<Payload extends object>(
  body: Payload,
  formData: FormData,
): FormData {
  Object.entries(body).forEach(([key, value]) => {
    formData.append(key, value)
  })
  return formData
}
