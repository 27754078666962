import { intervalToDuration } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styled'

const DatePart = ({ title, value }: { title: string; value: string }) => {
  const [t] = useTranslation()

  return (
    <Styled.Wrapper>
      <div className="min-w-[40px]">
        <div>{value}</div>
        <div className="text-body.small leading-body.small text-gray.600 font-normal">
          {t(title)}
        </div>
      </div>
    </Styled.Wrapper>
  )
}

export const Timer = ({
  className,
  targetTimestamp,
}: {
  className?: string
  targetTimestamp: string
}) => {
  const [remainingTime, setRemainingTime] = useState<{
    days?: number
    hours?: number
    minutes?: number
    seconds?: number
  }>({})

  useEffect(() => {
    const updateTimer = () => {
      const duration = intervalToDuration({
        start: new Date(),
        end: new Date(+targetTimestamp * 1000),
      })

      setRemainingTime(duration)
    }

    updateTimer()

    const intervalId = setInterval(updateTimer, 1000)

    return () => clearInterval(intervalId)
    
  }, [targetTimestamp])

  const padTime = (time?: number): string => {
    return time ? String(time).padStart(2, '0') : '00'
  }

  return (
    <div className={`${className} flex`}>
      <DatePart title="DAY" value={padTime(remainingTime.days)} />
      <DatePart title="HR" value={padTime(remainingTime.hours)} />
      <DatePart title="MIN" value={padTime(remainingTime.minutes)} />
      <DatePart title="SEC" value={padTime(remainingTime.seconds)} />
    </div>
  )
}
