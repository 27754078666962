import { useWindowSize } from '@uidotdev/usehooks'
import { forwardRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import type { Contest } from 'services/contests/types'
import { Title } from 'ui/atoms'

import * as Styled from './styled'

export const TeamsBanner = forwardRef<
  HTMLDivElement,
  {
    onChoice?: (team: string) => void
    choiceDisabled?: boolean
    showVotes?: boolean
  } & Pick<Contest['contest'], 'teamsBannerBlock'>
>(({ teamsBannerBlock, onChoice, choiceDisabled, showVotes = true }, ref) => {
  const [t] = useTranslation()

  const { width } = useWindowSize()

  return (
    <Styled.ChoiceWrapper
      ref={ref}
      background={
        width && width < 768
          ? teamsBannerBlock.backgroundImage.mob
          : teamsBannerBlock.backgroundImage.web
      }
      vsIcon={teamsBannerBlock.vsIcon.web}
    >
      {onChoice && (
        <Styled.Title
          level={width && width < 768 ? 2 : 1}
          className="text-gray.100"
        >
          Make your choice
        </Styled.Title>
      )}
      <Styled.TeamsWrapper>
        <div>
          <Styled.TeamImage>
            <img
              src={
                width && width < 768
                  ? teamsBannerBlock.first.image.mob
                  : teamsBannerBlock.first.image.web
              }
              alt={teamsBannerBlock.first.name}
            />
            <Styled.TeamData>
              <Title level={width && width < 600 ? 2 : 1}>
                {teamsBannerBlock.first.name}
              </Title>
              {showVotes && (
                <div className="flex gap-[8px] text-body.medium leading-body.medium">
                  <span>{t('Votes')}</span>
                  <span className="font-medium">
                    {teamsBannerBlock.first.votes}%
                  </span>
                </div>
              )}
            </Styled.TeamData>
          </Styled.TeamImage>
          {onChoice && (
            <Styled.ChoiceButton
              name="first-team"
              size={width && width < 600 ? 'small' : 'large'}
              onClick={() => onChoice('PATN_FIRST')}
              disabled={choiceDisabled}
            >
              {width && width < 768 ? (
                'Choose'
              ) : (
                <Trans
                  t={t}
                  i18nKey="Choose team"
                  values={{ team: teamsBannerBlock.first.name }}
                />
              )}
            </Styled.ChoiceButton>
          )}
        </div>
        <div>
          <Styled.TeamImage>
            <img
              src={
                width && width < 768
                  ? teamsBannerBlock.second.image.mob
                  : teamsBannerBlock.second.image.web
              }
              alt={teamsBannerBlock.second.name}
            />
            <Styled.TeamData>
              <Title level={width && width < 600 ? 2 : 1}>
                {teamsBannerBlock.second.name}
              </Title>
              {showVotes && (
                <div className="flex gap-[8px] text-body.medium leading-body.medium">
                  <span>{t('Votes')}</span>
                  <span className="font-medium">
                    {teamsBannerBlock.second.votes}%
                  </span>
                </div>
              )}
            </Styled.TeamData>
          </Styled.TeamImage>
          {onChoice && (
            <Styled.ChoiceButton
              name="second-team"
              size={width && width < 600 ? 'small' : 'large'}
              onClick={() => onChoice('PATN_SECOND')}
              disabled={choiceDisabled}
            >
              {width && width < 768 ? (
                'Choose'
              ) : (
                <Trans
                  t={t}
                  i18nKey="Choose team"
                  values={{ team: teamsBannerBlock.second.name }}
                />
              )}
            </Styled.ChoiceButton>
          )}
        </div>
      </Styled.TeamsWrapper>
    </Styled.ChoiceWrapper>
  )
})
